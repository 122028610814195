/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

export function emailValidatorReg(email: string) {
    const regExp = RegExp(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/);
    return regExp.test(email);
}

export function emailPlusAddressingValidatorReg(email: string) {
    const regExp = RegExp(/^([a-zA-Z0-9_\-\.+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/);
    return regExp.test(email);
}

export function urlValidatorReg(urlValue: string) {
    const handlerRegex = RegExp(
        /^(http(s)?:\/\/)?([w]{3}\.)?([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,63}(\/[a-zA-Z0-9\-._~:\/?#[\]@!$&'()*+,;=]*)?$/,
    );
    return handlerRegex.test(urlValue) && !/\s/.test(urlValue);
}

export function phoneNumberValidatorReg(phoneNumber: string) {
    const regex = /^0[2-478](?:[ -]?[0-9]){8}$|^04(?:[ -]?[0-9]){8}$/;
    return regex.test(phoneNumber);
}

export function svgValidatorReg(code: string) {
    return RegExp(/^<svg.*<\/svg>$/).test(code);
}

export function medicareCardNumberValidatorReg(medicare: string) {
    const formatted = medicare.replace(/\s+/g, '');

    // Ensure the number has a minimum of 10 digits and all numbers.
    const regex = /^\d{10,}$/;
    return regex.test(formatted);
}

/**
 * Validates string to ensure its a number.
 *
 * @param value String number value
 * @returns
 */
export function stringNumberValidatorReg(value: string) {
    const regex = /^\d+$/;
    return regex.test(value);
}

export function validateFloatingNumber(value: string) {
    const handlerRegex = /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/;
    return handlerRegex.test(value);
}

export function isValidSVGReg(svgString: string): boolean {
    // Regular expression to check for SVG structure
    const svgRegex = /<svg[^>]*>([\s\S]*?)<\/svg>/i;

    return svgRegex.test(svgString.trim());
}

export function isValidUUIDReg(uuid: string) {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(uuid);
}

export function validatePassword(password: string) {
    // Check each individual requirement
    const minLength = /.{8,}/;
    const hasLowercase = /[a-z]/;
    const hasUppercase = /[A-Z]/;
    const hasNumber = /[0-9]/;
    const hasSpecialChar = /[!@#$%^&*]/;

    // Count how many of the character class checks pass
    const checks = [
        hasLowercase.test(password),
        hasUppercase.test(password),
        hasNumber.test(password),
        hasSpecialChar.test(password),
    ];

    // Validate password based on criteria
    return minLength.test(password) && checks.filter(Boolean).length >= 3;
}

/**
 * Determines if a given string contains any links or URL patterns.
 *
 * @param {string} input - The string to evaluate for the presence of links or URLs.
 * @returns {boolean} - Returns `true` if the input contains any of the following patterns; otherwise, `false`:
 *   - **HTTP/HTTPS URLs**: Full web addresses with optional query parameters (e.g., "http://example.com", "https://example.com/path?query=value").
 *   - **FTP URLs**: Links to resources on FTP servers (e.g., "ftp://ftp.example.com/file.txt").
 *   - **File URLs**: Local file system references (e.g., "file:///C:/path/to/file.txt").
 *   - **Mailto URLs**: Email links (e.g., "mailto:example@example.com").
 *   - **Tel URLs**: Phone number links (e.g., "tel:+1234567890").
 *   - **Generic Domains**: Includes standard and custom domains, with optional paths (e.g., "example.com", "example.ly/3E7bmiv").
 *
 * The function combines all relevant patterns into a single regular expression for efficient evaluation.
 */
export function containsLinks(input: string): boolean {
    const linkPattern = new RegExp(
        [
            /https?:\/\/[^\s/$.?#].[^\s]*/, // HTTP, HTTPS URLs with or without query parameters
            /ftp:\/\/[^\s/$.?#].[^\s]*/, // FTP URLs
            /file:\/\/\/[A-Za-z]:\/[^\s]+/, // File URLs
            /mailto:[^\s@]+@[^\s@]+\.[^\s@]+/, // Mailto URLs
            /tel:\+?[0-9]{1,15}/, // Tel URLs
            /\b[a-zA-Z0-9-]+\.[a-z]{1,10}(\/[^\s]*)?\b/, // Generic domains with optional paths (e.g., example.ly, example.ly/3E7bmiv)
        ]
            .map((regex) => regex.source) // Extracts the regex source
            .join('|'), // Joins them with OR `|`
        'i', // Case-insensitive flag
    );

    return linkPattern.test(input);
}

/**
 * Checks if the given string contains potential SQL injection patterns.
 *
 * @param {string} input - The input string to check for SQL injection attempts.
 * @returns {boolean} - Returns `true` if the input string contains any of the following SQL injection patterns; otherwise, `false`:
 *   - Special characters commonly used in SQL injection (e.g., single quotes `'`, double quotes `"`, semicolon `;`, backslash `\`, or hash `#`)
 *   - SQL comment patterns (e.g., "-- ")
 *   - Common SQL keywords (e.g., "SELECT", "UNION", "INSERT", "DELETE", "DROP", "UPDATE", "ALTER", "EXEC", "CREATE", "WHERE", "FROM")
 *
 * The function identifies these patterns using regular expressions and flags the input if a match is found.
 */
export function containsSQLInjection(input: string): boolean {
    // More precise SQL injection patterns
    const sqlInjectionPatterns = [
        /(--|#)\s/, // SQL comment patterns
        /['";]/g, // Dangerous characters typically used in combination with other patterns
        /\b(SELECT|UNION|INSERT|DELETE|DROP|UPDATE|ALTER|EXEC|CREATE|WHERE|FROM|OR|AND)\b/i, // Common SQL keywords
    ];

    // Check if any of the patterns are matched
    const potentialInjection = sqlInjectionPatterns.some((pattern) => pattern.test(input));

    // Further validation to allow valid use of apostrophes
    const validApostrophePattern = /^[a-zA-Z\s]+('[a-zA-Z\s]+)?$/;

    // Return true only if it's identified as potential injection and does not match valid patterns
    return potentialInjection && !validApostrophePattern.test(input);
}

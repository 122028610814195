/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { CreatedAtTimestampSchema, DateSchema, EmailAddressSchema, IdEntitySchema } from '..';
import {
    GenderSchema,
    MedicalHistoryStatusSchema,
    MedicalRequestStatusSchema,
    MedicalRequestTypeSchema,
} from '../../constants';
import { maybe } from '../../utils/runtypes';
import {
    MedicalRequestFlagResourceSchema,
    MedicalRequestNoteResourceSchema,
    MedicalRequestScriptDatabaseRecordSchema,
    MedicalRequestServiceSchema,
} from './request';

const MedicalHistoryPatientSchema = r.Record({
    address: r.String,
    last_name: r.String,
    first_name: r.String,
    gender: GenderSchema,
    phone_number: r.String,
    date_of_birth: r.Number,
    email: EmailAddressSchema,
    medicare: r.Optional(r.String),
});
export type MedicalHistoryPatient = r.Static<typeof MedicalHistoryPatientSchema>;

const MedicalDoctorSchema = r.Record({
    id: r.String,
    last_name: r.String,
    first_name: r.String,
});
export type MedicalHistoryDoctor = r.Static<typeof MedicalDoctorSchema>;

export const MedicalHistorySchema = r
    .Record({
        title: r.String,
        category: r.String,
        prescription: r.String,
        doctor: MedicalDoctorSchema,
        request_reference_id: r.String,
        profile_reference_id: r.String,
        status: MedicalHistoryStatusSchema,
        patient: MedicalHistoryPatientSchema,
    })
    .extend(CreatedAtTimestampSchema.fields);
export type MedicalRequestHistory = r.Static<typeof MedicalHistorySchema>;

const MedicalHistoryResourceSchema = MedicalHistorySchema.extend(CreatedAtTimestampSchema.fields);
export type MedicalHistoryResource = r.Static<typeof MedicalHistoryResourceSchema>;

// Patient medical history records.
export const MedicalRequestHistoryRecordSchema = r
    .Record({
        doctor_name: r.String,
        completed_at: DateSchema,
        request_reason: maybe(r.String),
        rejection_reason: maybe(r.String),
        status: MedicalRequestStatusSchema,
        service: MedicalRequestServiceSchema,
        request_type: MedicalRequestTypeSchema,
        flags: r.Array(MedicalRequestFlagResourceSchema),
        notes: r.Array(MedicalRequestNoteResourceSchema),
        prescriptions: r.Array(MedicalRequestScriptDatabaseRecordSchema),
    })
    .extend(IdEntitySchema.fields)
    .extend(CreatedAtTimestampSchema.fields);

export type MedicalRequestHistoryRecord = r.Static<typeof MedicalRequestHistoryRecordSchema>;

export const PatientMedicalHistorySchema = r.Array(MedicalRequestHistoryRecordSchema);

/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { runtypeFromEnum } from '../../utils/runtypes';

export enum SmokingCessationService {
    SmokingCessation = 'Smoking Cessation Plan',
}
export const SmokingCessationServiceTitleSchema = runtypeFromEnum(SmokingCessationService);

/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { runtypeFromEnum } from '../../../utils/runtypes';
import { HydrationTherapyServicesSchema, SkincareHydrationCategoriesSchema } from './hydration';
import { AestheticsAndCosmeticsServicesSchema, SkincareAestheticsCategoriesSchema } from './ aesthetics';

export const SKINCARE_DISCOUNT_FLOAT = 100;

export enum SkincareAppointmentStatus {
    Scheduled = 'Scheduled',
    Cancelled = 'Cancelled',
    Completed = 'Completed',
}
export const SkincareAppointmentStatusSchema = runtypeFromEnum(SkincareAppointmentStatus);

export enum SkincareService {
    SkincareClinic = 'Skincare Clinic',
    SkincareAppointment = 'Skincare Appointment',
}
export const SkincareServiceTitleSchema = runtypeFromEnum(SkincareService);

// AussieScripts skincare services.
export enum SkincareCategoriesService {
    HydrationTherapies = 'Hydration Therapies',
    AestheticsAndCosmetics = 'Aesthetics and Cosmetics',
}
export const SkincareCategoriesServiceSchema = runtypeFromEnum(SkincareCategoriesService);

// Skincare subcategories.
export const SkincareSubCategoriesSchema = SkincareHydrationCategoriesSchema.Or(SkincareAestheticsCategoriesSchema);
export type SkincareSubCategories = r.Static<typeof SkincareSubCategoriesSchema>;

// Skincare service titles.
export const AussieSkincareServiceTitlesSchema = HydrationTherapyServicesSchema.Or(
    AestheticsAndCosmeticsServicesSchema,
);
export type AussieSkincareServiceTitles = r.Static<typeof AussieSkincareServiceTitlesSchema>;

// Physical Characteristics
export enum SkincareEyeColor {
    LightBlueLightGreyLightGreen = 'Light Blue, Light Grey, Light Green',
    BlueGreyGreen = 'Blue, Grey, Green',
    HazelLightBrown = 'Hazel or Light Brown',
    DarkBrown = 'Dark Brown',
    BrownishBlack = 'Brownish Black',
}
export const SkincareEyeColorSchema = runtypeFromEnum(SkincareEyeColor);

export enum SkincareHairColor {
    SandyRedLightBlond = 'Sandy Red or Light Blond',
    Blond = 'Blond',
    ChestnutDarkBlondLightBrown = 'Chestnut/Dark Blond/Light Brown',
    DarkBrown = 'Dark Brown',
    Black = 'Black',
}
export const SkincareHairColorSchema = runtypeFromEnum(SkincareHairColor);

export enum SkincareSkinColor {
    IvoryWhite = 'Ivory White',
    FairPaleReddish = 'Fair/Pale or Reddish',
    FairBeigeTint = 'Fair with Beige tint',
    OliveLightBrown = 'Olive or Light Brown',
    DarkBrownBlack = 'Dark Brown or Black',
}
export const SkincareSkinColorSchema = runtypeFromEnum(SkincareSkinColor);

export enum SkincareFreckles {
    Many = 'Many',
    Several = 'Several',
    Few = 'Few',
    Incidental = 'Incidental',
    None = 'None',
}
export const SkincareFrecklesSchema = runtypeFromEnum(SkincareFreckles);

// Reaction to Sun Exposure
export enum SkincareReaction {
    AlwaysBurnsBlistersPeels = 'Always Burns, Blisters and Peels',
    OftenBurnsBlistersPeels = 'Often Burns, Blisters and Peels',
    BurnsModerately = 'Burns Moderately',
    BurnsRarely = 'Burns Rarely',
    NeverBurns = 'Never Burns',
}
export const SkincareReactionSchema = runtypeFromEnum(SkincareReaction);

export enum SkincareTanDegree {
    HardlyOrNotAtAll = 'Hardly or not at all',
    LightColorTan = 'Light Colour Tan',
    ReasonableTan = 'Reasonable Tan',
    TanVeryEasily = 'Tan Very Easily',
    TurnDarkBrownQuickly = 'Turn Dark Brown Quickly',
}
export const SkincareTanDegreeSchema = runtypeFromEnum(SkincareTanDegree);

export enum SkincareTansAfter {
    Never = 'Never',
    Seldom = 'Seldom',
    Sometimes = 'Sometimes',
    Often = 'Often',
    Always = 'Always',
}
export const SkincareTansAfterSchema = runtypeFromEnum(SkincareTansAfter);

export enum SkincareFaceSensitivity {
    VerySensitive = 'Very sensitive',
    Sensitive = 'Sensitive',
    Normal = 'Normal',
    Resistant = 'Resistant',
    NeverHadAProblem = 'Never Had a Problem',
}
export const SkincareFaceSensitivitySchema = runtypeFromEnum(SkincareFaceSensitivity);

// Tanning Habits
export enum SkincareLastSunExposure {
    MoreThanThreeMonthsAgo = 'More Than 3 Months Ago',
    TwoToThreeMonthsAgo = '2-3 Months Ago',
    OneToTwoMonthsAgo = '1-2 Months Ago',
    LessThanAMonthAgo = 'Less Than a Month Ago',
    LessThanTwoWeeksAgo = 'Less Than 2 Weeks Ago',
}
export const SkincareLastSunExposureSchema = runtypeFromEnum(SkincareLastSunExposure);

export enum SkincareAreaExposure {
    Never = 'Never',
    HardlyEver = 'Hardly Ever',
    Sometimes = 'Sometimes',
    Often = 'Often',
    Always = 'Always',
}
export const SkincareAreaExposureSchema = runtypeFromEnum(SkincareAreaExposure);

// Fitzpatrick skin type classifications.
export enum FitzpatrickClassification {
    GenericDisposition = 'Generic Disposition',
    ReactionToSunExposure = 'Reaction To Sun Exposure',
    TanningHabits = 'Tanning Habits',
}

/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { isValid } from 'date-fns';
import {
    MEDICAL_SUMMARY_LIMIT,
    DISCOUNT_COUNT_LIMIT,
    PERSONAL_NAME_MAX_CHARACTERS,
    EMERGENCY_CONTACT_RELATIONSHIP_LIMIT,
} from '../constants';
import {
    urlValidatorReg,
    phoneNumberValidatorReg,
    svgValidatorReg,
    medicareCardNumberValidatorReg,
    stringNumberValidatorReg,
    isValidSVGReg,
    isValidUUIDReg,
    emailPlusAddressingValidatorReg,
    emailValidatorReg,
    containsLinks,
    containsSQLInjection,
} from '../regex';

export function validateEmailAddress(email: string) {
    return emailValidatorReg(email) || emailPlusAddressingValidatorReg(email) || 'Invalid email address value.';
}

export function validatePersonalName(name: string) {
    const count = name.trim().length;
    return (
        (count > 0 && count <= PERSONAL_NAME_MAX_CHARACTERS && !containsLinks(name) && !containsSQLInjection(name)) ||
        `Invalid personal name. Provided name should have 1 or less than ${PERSONAL_NAME_MAX_CHARACTERS} characters.`
    );
}

export function validateEmergencyContactRelationship(name: string) {
    const count = name.trim().length;
    return count <= EMERGENCY_CONTACT_RELATIONSHIP_LIMIT || 'Invalid emergency contact relationship value.';
}

export function validateURLValue(urlAddress: string) {
    return urlValidatorReg(urlAddress) || 'Invalid URL value.';
}

export function validateMedicalSummary(summary: string) {
    return summary.trim().length <= MEDICAL_SUMMARY_LIMIT || 'Invalid medical summary value.';
}

export function validatePhoneNumber(phoneNumber: string) {
    return phoneNumberValidatorReg(phoneNumber) || 'Invalid phone number';
}

export function validateMedicareCardNumber(medicareCardNumber: string) {
    return medicareCardNumberValidatorReg(medicareCardNumber) || 'Invalid medicare card number';
}

export function validateNumbersOnly(medicareCardNumber: string) {
    return stringNumberValidatorReg(medicareCardNumber) || 'Invalid value, expected a number';
}

export function validateDiscountCode(code: string) {
    const count = code.trim().length;
    return count === DISCOUNT_COUNT_LIMIT || 'Invalid discount code value.';
}

export function validateRequestDeclaration(signature: string) {
    return svgValidatorReg(signature) || 'Invalid signature declaration';
}

export function validateIsoDateString(isoDate: string) {
    return isValid(new Date(isoDate)) || 'Invalid signature declaration';
}

export function validateMedicalCertificateDateString(dateString: string) {
    const datePattern = /^(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday),\s+(January|February|March|April|May|June|July|August|September|October|November|December)\s+\d{1,2}(st|nd|rd|th)\s+\d{4}$/;
    return datePattern.test(dateString) || 'Invalid signature declaration';
}

export function validateSVGString(svgString: string) {
    return isValidSVGReg(svgString) || 'Invalid signature declaration';
}

export function validateUUID(uuid: string) {
    return isValidUUIDReg(uuid) || 'Invalid resource UUID identifier';
}

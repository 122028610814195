/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import {
    GetDashboardArticlesSchema,
    ArticleDashboardResourceSchema,
    CreateArticleSchema,
    UpdateArticleSchema,
    GetArticlesSchema,
    GetArticleResponseSchema,
} from '../types/articles';
import { EndpointSync, HttpMethod } from '../utils/endpointSync';

// Articles infirmary dashboard endpoints.
export const GetDashboardArticlesEndpoint = new EndpointSync({
    method: HttpMethod.GET,
    path: '/',
    responseValidator: GetDashboardArticlesSchema,
});

export const CreateArticleEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/create',
    requestValidator: CreateArticleSchema,
    responseValidator: ArticleDashboardResourceSchema,
});

export const UpdateArticleEndpoint = new EndpointSync({
    method: HttpMethod.PATCH,
    path: '/update/:articleId',
    requestValidator: UpdateArticleSchema,
    responseValidator: ArticleDashboardResourceSchema,
});

export const UpdateArticlePublicationEndpoint = new EndpointSync({
    method: HttpMethod.PATCH,
    path: '/publication/:articleId',
    responseValidator: ArticleDashboardResourceSchema,
});

export const DeleteArticleEndpoint = new EndpointSync({
    method: HttpMethod.DELETE,
    path: '/:articleId',
});

// Articles public website endpoints
export const GetArticlesEndpoint = new EndpointSync({
    method: HttpMethod.GET,
    path: '/health',
    responseValidator: GetArticlesSchema,
});

export const GetArticleEndpoint = new EndpointSync({
    method: HttpMethod.GET,
    path: '/health/read/:articleId',
    responseValidator: GetArticleResponseSchema,
});

/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { ERxPadExportProfileSchema, ConsultationNotificationSchema } from '../types';
import { ConsultationFileResourceSchema, ConsultationFileResourcesSchema } from '../types/file';
import {
    InfirmaryRequestSchema,
    GetInfirmaryPendingRequestSchema,
    GetInfirmaryRecordsResponseSchema,
    InfirmaryTakeConsultationResponseSchema,
    CancelConsultationRequestSchema,
    RejectConsultationSchema,
    MedicalCertificateConsultationRequestSchema,
    ConsultationScriptResponseSchema,
    PathologyTestConsultationRequestSchema,
    MedicalImagingOrRadiologyConsultationRequestSchema,
    SleepStudyRequestConsultationSchema,
    MentalHealthCareConsultationRequestSchema,
    DoctorLetterConsultationRequestSchema,
    SpecialistReferralConsultationRequestSchema,
    FinalizeConsultationRequestSchema,
    GetInfirmaryHistoryResponseSchema,
    InfirmaryConsultationSchema,
} from '../types/infirmary';
import { PatientMedicalHistorySchema } from '../types/medical/history';
import { MedicalProfileResourceSchema } from '../types/medical/profile';
import {
    MedicalRequestFlagSchema,
    MedicalRequestNoteResourceSchema,
    MedicalRequestNoteSchema,
} from '../types/medical/request';
import { EScriptRequestSchema, GetEScriptMedicationSchema } from '../types/prescription';
import { EndpointSync, HttpMethod } from '../utils/endpointSync';

export const GetInfirmaryRequestsEndpoint = new EndpointSync({
    path: '/',
    method: HttpMethod.GET,
    responseValidator: GetInfirmaryRecordsResponseSchema,
});

export const SendPatientConsultationNotificationEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/consultation/:medicalRequestId/patient/:notificationType/notification',
    responseValidator: ConsultationNotificationSchema,
});

export const GetInfirmaryHistoryEndpoint = new EndpointSync({
    path: '/consultation/history',
    method: HttpMethod.GET,
    responseValidator: GetInfirmaryHistoryResponseSchema,
});

export const GetHistoryMedicalRequestConsultationEndpoint = new EndpointSync({
    path: '/consultation/history/:consultationId',
    method: HttpMethod.GET,
    responseValidator: InfirmaryConsultationSchema,
});

export const GetInfirmaryMedicationEndpoint = new EndpointSync({
    path: '/medication',
    method: HttpMethod.GET,
    responseValidator: GetEScriptMedicationSchema,
});

export const CheckInfirmaryPendingRequestEndpoint = new EndpointSync({
    path: '/consultation/pending',
    method: HttpMethod.GET,
    responseValidator: GetInfirmaryPendingRequestSchema,
});

export const TakeInfirmaryRequestEndpoint = new EndpointSync({
    path: '/consultation/:consultationId/request',
    method: HttpMethod.GET,
    responseValidator: InfirmaryTakeConsultationResponseSchema,
});

export const CancelInfirmaryConsultationEndpoint = new EndpointSync({
    path: '/consultation/cancel/:consultationId',
    method: HttpMethod.PATCH,
    requestValidator: CancelConsultationRequestSchema,
});

export const CreateInfirmaryRequestFlagEndpoint = new EndpointSync({
    path: '/flag/:consultationId',
    method: HttpMethod.POST,
    requestValidator: MedicalRequestFlagSchema,
    responseValidator: InfirmaryRequestSchema,
});

export const CreateInfirmaryRequestNoteEndpoint = new EndpointSync({
    path: '/note/:consultationId',
    method: HttpMethod.POST,
    requestValidator: MedicalRequestNoteSchema,
    responseValidator: MedicalRequestNoteResourceSchema,
});

export const CreateInfirmaryEScriptEndpoint = new EndpointSync({
    path: '/escript/:requestId',
    method: HttpMethod.POST,
    requestValidator: EScriptRequestSchema,
});

// Doctor consultation endpoints.
export const ApproveAppScriptEndpoint = new EndpointSync({
    path: '/consultation/approve/:requestId',
    method: HttpMethod.PATCH,
    requestValidator: MedicalRequestNoteSchema,
});

export const ResendAppScriptEndpoint = new EndpointSync({
    path: '/consultation/resend/letter/:requestId',
    method: HttpMethod.PATCH,
});

export const RejectConsultationEndpoint = new EndpointSync({
    path: '/consultation/reject/:requestId',
    method: HttpMethod.PATCH,
    requestValidator: RejectConsultationSchema,
});

export const FinalizeConsultationRequestEndpoint = new EndpointSync({
    path: '/consultation/finalize/:requestId',
    method: HttpMethod.PATCH,
    requestValidator: FinalizeConsultationRequestSchema,
});

export const PathologyTestRequestEndpoint = new EndpointSync({
    path: '/consultation/pathology-test/:requestId',
    method: HttpMethod.PATCH,
    requestValidator: PathologyTestConsultationRequestSchema,
    responseValidator: ConsultationScriptResponseSchema,
});

export const MedicalImagingOrRadiologyRequestEndpoint = new EndpointSync({
    path: '/consultation/medical-imaging-radiology/:requestId',
    method: HttpMethod.PATCH,
    requestValidator: MedicalImagingOrRadiologyConsultationRequestSchema,
    responseValidator: ConsultationScriptResponseSchema,
});

export const SleepStudyRequestEndpoint = new EndpointSync({
    path: '/consultation/sleep-study/:requestId',
    method: HttpMethod.PATCH,
    requestValidator: SleepStudyRequestConsultationSchema,
    responseValidator: ConsultationScriptResponseSchema,
});

export const MentalHealthCareRequestEndpoint = new EndpointSync({
    path: '/consultation/mental-health-treatment/:requestId',
    method: HttpMethod.PATCH,
    requestValidator: MentalHealthCareConsultationRequestSchema,
    responseValidator: ConsultationScriptResponseSchema,
});

export const DoctorLetterRequestEndpoint = new EndpointSync({
    path: '/consultation/doctor-letter/:requestId',
    method: HttpMethod.PATCH,
    requestValidator: DoctorLetterConsultationRequestSchema,
    responseValidator: ConsultationScriptResponseSchema,
});

export const SpecialistReferralRequestEndpoint = new EndpointSync({
    path: '/consultation/specialist-referral/:requestId',
    method: HttpMethod.PATCH,
    requestValidator: SpecialistReferralConsultationRequestSchema,
    responseValidator: ConsultationScriptResponseSchema,
});

export const MedicalCertificateRequestEndpoint = new EndpointSync({
    path: '/consultation/medical-certificate/:requestId',
    method: HttpMethod.PATCH,
    requestValidator: MedicalCertificateConsultationRequestSchema,
    responseValidator: ConsultationScriptResponseSchema,
});

export const UpdateConsultationDocumentViewStateEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/consultation/:requestId/document/state',
    responseValidator: ConsultationFileResourceSchema,
});

export const UploadConsultationFileEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/consultation/:requestId/upload/:category/upload',
    responseValidator: ConsultationFileResourcesSchema,
});

export const ChangeConsultationMedicalProfileEndpoint = new EndpointSync({
    method: HttpMethod.GET,
    path: '/consultation/:consultationId/profile/:profileId/patient/:patientId',
    responseValidator: MedicalProfileResourceSchema,
});

export const GetPatientMedicalHistoryEndpoint = new EndpointSync({
    method: HttpMethod.GET,
    path: '/patient/:patientId/medical/history',
    responseValidator: PatientMedicalHistorySchema,
});

// Export eRxPad patient medical profiles.
export const ExportPatientERxPadRecordEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/patient/medical-profile/erxpad/export',
    requestValidator: ERxPadExportProfileSchema,
});
